// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-about-mdx": () => import("../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-external-wall-insulation-mdx": () => import("../src/pages/external-wall-insulation.mdx" /* webpackChunkName: "component---src-pages-external-wall-insulation-mdx" */),
  "component---src-pages-render-suppliers-index-mdx": () => import("../src/pages/render-suppliers/index.mdx" /* webpackChunkName: "component---src-pages-render-suppliers-index-mdx" */),
  "component---src-pages-render-suppliers-k-rend-mdx": () => import("../src/pages/render-suppliers/k-rend.mdx" /* webpackChunkName: "component---src-pages-render-suppliers-k-rend-mdx" */),
  "component---src-pages-render-suppliers-parex-mdx": () => import("../src/pages/render-suppliers/parex.mdx" /* webpackChunkName: "component---src-pages-render-suppliers-parex-mdx" */),
  "component---src-pages-render-suppliers-weber-mdx": () => import("../src/pages/render-suppliers/weber.mdx" /* webpackChunkName: "component---src-pages-render-suppliers-weber-mdx" */),
  "component---src-pages-render-suppliers-wetherby-mdx": () => import("../src/pages/render-suppliers/wetherby.mdx" /* webpackChunkName: "component---src-pages-render-suppliers-wetherby-mdx" */),
  "component---src-pages-render-types-acrylic-render-mdx": () => import("../src/pages/render-types/acrylic-render.mdx" /* webpackChunkName: "component---src-pages-render-types-acrylic-render-mdx" */),
  "component---src-pages-render-types-base-coats-and-adhesives-mdx": () => import("../src/pages/render-types/base-coats-and-adhesives.mdx" /* webpackChunkName: "component---src-pages-render-types-base-coats-and-adhesives-mdx" */),
  "component---src-pages-render-types-brick-effect-render-mdx": () => import("../src/pages/render-types/brick-effect-render.mdx" /* webpackChunkName: "component---src-pages-render-types-brick-effect-render-mdx" */),
  "component---src-pages-render-types-index-mdx": () => import("../src/pages/render-types/index.mdx" /* webpackChunkName: "component---src-pages-render-types-index-mdx" */),
  "component---src-pages-render-types-lime-render-mdx": () => import("../src/pages/render-types/lime-render.mdx" /* webpackChunkName: "component---src-pages-render-types-lime-render-mdx" */),
  "component---src-pages-render-types-pebble-dash-mdx": () => import("../src/pages/render-types/pebble-dash.mdx" /* webpackChunkName: "component---src-pages-render-types-pebble-dash-mdx" */),
  "component---src-pages-render-types-polymer-render-mdx": () => import("../src/pages/render-types/polymer-render.mdx" /* webpackChunkName: "component---src-pages-render-types-polymer-render-mdx" */),
  "component---src-pages-render-types-sand-and-cement-mdx": () => import("../src/pages/render-types/sand-and-cement.mdx" /* webpackChunkName: "component---src-pages-render-types-sand-and-cement-mdx" */),
  "component---src-pages-render-types-scratch-render-mdx": () => import("../src/pages/render-types/scratch-render.mdx" /* webpackChunkName: "component---src-pages-render-types-scratch-render-mdx" */)
}

